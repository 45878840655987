.wrap {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: calc(100% - 72px);
  padding: 0;
  margin: 36px;
  box-shadow: 0 0 5px rgba(23, 24, 24, 0.05), 0 1px 2px rgba(0, 0, 0, 0.015);
  border-radius: 10px;
  height: calc(100% - 48px);
  background-color: #fff;

  > .SharedDataView__Container-sc-1o3o01v-4 fCFKjf {
    height: calc(100% - 108px);
  }

  @media(max-width: 992px) {
    margin: 36px 20px; 
    width: calc(100% - 40px);
    min-width: 650px;
  }
}

.content-wrap {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: calc(100% - 109px);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 375px;
    width: 1px;
    background-color: #e7e8e9;
  }

  .content-left {
    width: 375px;
    height: 100%;
    // border-right: 1px solid #e7e8e9;
    // padding-bottom: 97px;
  }

  .content-right {
    width: calc(100% - 390px);
    height: 100%;
    overflow-y: auto;
  }
}

// person 영역
.person-wrap {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 15px);
  height: 108px;
  border-bottom: 1px solid #e7e8e9;

  .name-wrap {
    display: flex;
    flex-direction: column;
  }

  .person {
    font-family: Noto Sans KR;
    font-style: normal;
    text-align: left;
    padding-left: 32px;

    &.name {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.5px;
    }

    &.info {
      @extend %fontNomal;
      line-height: 14px;
      padding-top: 8px;
    }
  }

  .btn-down-wrap {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -22px;

    li { list-style: none;}

    .btn-down {
      position: relative;
      height: 44px;
      padding: 13px 32px 13px 16px;
      @extend %fontNomal;
      font-weight: 500;
      line-height: 18px;  
      border: 1px solid #C9CCCE;
      background: #fff;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
      border-radius: 4px;

      &:before {
        content: '';
        position: absolute;
        right: 12px;
        top: 12px;
        width: 20px;
        height: 20px;
        background: url('../../assets/mydata/caret-down_minor.svg') left top no-repeat;
      }
    }

    li.show  {  
      .btn-down {
        background: #7F8694;
        box-shadow: inset 0px 2px 0px rgba(0, 0, 0, 0.05);
        border: 1px solid #7F8386;
        color: #fff;
  
        &:before {
          background: url('../../assets/mydata/caret-down_minor_selected.svg') left top no-repeat;
        }
  
      }
    }
  }

  .down-layer {
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px;
    min-width: 164px;

    .icon {
      width: 17.66px;
      height: 20px;
    }

    .export_text {
      @extend %fontNomal;
      line-height: 20px;
      color: #202223;
    }

    .dropdown-item {
      padding: 10px;
      border-bottom: 0;
    }
  }





  
}
 


.person-wrap-temp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(100% - 15px);
  height: 72px;
  border-bottom: 1px solid #e7e8e9;

  .person {
    font-family: Noto Sans KR;
    font-style: normal;
    text-align: left;
    padding-left: 32px;

    &.name {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.5px;
    }
  }
}

// filter 영역
.filter-wrap {
  padding: 23px 23px 0 23px;
  border-bottom: 1px solid #f0f2f4;
  margin-bottom: 12px;

  .title {
    position: relative;
    padding-top: 12px;
    padding-bottom: 23px;
    @extend %fontLarge;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -0.5px;
  }
}

// form 영역
.form-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;

  .dash {
    color: #7f8694;
    @extend %fontNomal;
    line-height: 18px;
    padding: 0 16px;
  }

  .input-block {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    > label {
      height: 17px;
      margin: 0;
      transform-origin: top left;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      transition: display 1.5s;
      @extend %inputLabel;
      color: #7f8694;
      font-size: 19px;
      z-index: 1;

      &.label {
        transform: translateY(24px) translateX(0px) scale(1);
      }

      &.focus {
        height: 17px;
        line-height: 17px;
        transform: translateY(2px);
        @extend %fontNomal;
        pointer-events: auto;

        //   + .input-wrap {
        //     border-bottom: 1px solid #afb5c2;
        //   }
      }
    }

    .input-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      position: relative;
      width: 100%;
      height: 33px;
      margin-bottom: 8px;
      border-bottom: 1px solid #e6e8ed;

      input {
        width: 100%;
        height: 25px;
        border: none;
        outline: none;
        @extend %fontXXlarge;
        line-height: 25px;
        color: #1b1e25;
      }
    }
  }
}

// content 우측 영역
.content-title-wrap {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 23px;
  border-bottom: 1px solid #f0f2f4;

  .content-title {
    @extend %fontXXlarge;
    line-height: 25px;
  }

  .content-date {
    @extend %fontSmall;
    line-height: 17px;
    color: #7f8694;
  }
}

// 주차
.period-wrap {
  padding: 42px 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  .block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 282px;
    height: 52px;
    padding: 0 14px;
    background-color: #f0f2f4;
    border-radius: 12px;

    img {
      width: 24px;
      height: 24px;
    }

    .period {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        color: #1b1e25;
        text-align: center;

        &:first-child {
          @extend %fontLarge;
          line-height: 18px;
        }

        &:last-child {
          @extend %fontSmall;
          line-height: 16px;
        }
      }
    }
  }
}
