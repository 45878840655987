// detail

.detail-wrap {
  padding: 0;

  .date-wrap {
    padding: 0 23px 25px 23px;
    border-bottom: 1px solid #f0f2f4;
    margin-bottom: 24px;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  .date {
    width: 352px;
    padding: 16px 0;
    @extend %fontXXlarge;
    line-height: 25px;
    color: #404346;
    border-bottom: 1px solid #f0f2f4;
    // margin-bottom: 16px;
  }

  .section {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-top: 24px;
    // padding-top: 24px;



    &.pd23 {
      padding-left: 23px;
      padding-right: 23px;
    }

    &.toggle {
      &.show {
        .title {
          color: #0085ff;
        }
      }

      &.hide {
        :nth-child(2) {
          visibility: hidden;
        }
      }

      img {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .title {
      width: calc(100% - 52px);
      padding-bottom: 8px;
      color: #7f8694;
      @extend %fontNomal;
      line-height: 17px;

      &.pb-16 {
        padding-bottom: 16px !important;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;

      > span {
        display: block;
        width: calc(100% - 52px);
        color: #1b1e25;
        @extend %fontXLarge;
        line-height: 18px;
      }

      &.mb-32 {
        margin-bottom: 32px;
      }

    }

    .toggle-box {
      width: 100%;
      background-color: #f0f2f4;
      border-radius: 12px;
      padding: 16px;
      margin-top: 12px;

      .inner {
        color: #1b1e25;
        padding-bottom: 20px;
        border-bottom: 1px solid #d9dde4;
        margin-bottom: 20px;

        &:last-child {
          padding-bottom: 0;
          border-bottom: none;
          margin-bottom: 0;
        }

        .name {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          @extend %fontLarge;
          font-weight: 500;
          line-height: 18px;
          padding-bottom: 12px;

          &.pb-0 {
            padding-bottom: 0 !important;
          }

          &.pb-4 {
            padding-bottom: 4px !important;
          }

          > span {
            padding-left: 4px;
          }

          // 검진일 경우
          + .other {
            align-items: center;
            justify-content: flex-end;

            > span {
              @extend %fontLarge;
              line-height: 18px;
              padding-left: 4px;
              text-align: right;

              &.unit {
                color: #7f8386;
              }
            }
          }
        }

        .detail {
          @extend %fontSmall;
          line-height: 16px;
        }

        // 검사
        .detail-wrap {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-bottom: 12px;

          &:last-child {
            padding-bottom: 0;
          }

          .name {
            flex: 1;
            align-items: center;
            @extend %fontSmall;
            line-height: 16px;
            padding-bottom: 0;
          }

          .other {
            align-items: center;
            justify-content: flex-end;

            > span {
              @extend %fontSmall;
              line-height: 16px;
              padding-left: 10px;
              text-align: right;
            }
          }
        }

        // 투약
        .other2 {
          align-items: flex-end;
          justify-content: center;
          padding-left: 16px;

          span {
            display: inline-block;
            color: #404346;
            text-align: right;
            white-space: nowrap;

            &:last-child {
              @extend %fontSmall;
              color: #7f8386;
              padding-top: 4px;
              text-align: right;
            }
          }
        }
      }
    }
  }

  .f-row {
    display: flex;
    flex-direction: row;
  }

  .f-column {
    display: flex;
    flex-direction: column;
  }

  .f-between {
    justify-content: space-between;
  }

  .pb-16 {
    padding-bottom: 16px;
  }

  .pb-12 {
    padding-bottom: 12px;
  }

  .pt-24 {
    padding-top: 24px;
  }

  .text-faint {
    color: #7F8386;
  }
}


.divide-bg {
  border-top: 1px solid #F0F2F4;
  width: 100%;
  height: 12px;
  background-color: #F9FAFB;
  margin-top: 30px;
  margin-bottom: 12px;
}


.total {
  &-result {
    padding: 52px 52px 22px 52px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-title {
    padding-bottom: 8px;
    @extend %fontXXlarge;
    line-height: 25px;
    color: #1b1e25;
  }

  &-diagnosis {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 23px;
    padding: 8px 12px;
    border-radius: 38px;

    > span {
      @extend %fontXXlarge;
      line-height: 25px;
    }

    &.success {
        background-color: #BAEAD3;

        > span {
          color: #236B5E;
        }
    }

    &.warning {
      background-color: #FDE9C6;

      > span {
        color: #9C4221;
      }
    }

    &.critical {
        background-color: #FFDBDB;
        > span {
          color: #AD2424;
        }
    }

    &.primary {
      background-color: #EBE8FF;
      > span {
        color: #0085FF;
      }
    }

  }
  
  &-type {
    @extend %fontLarge;
    line-height: 18px;
    color: #7f8694;
  }
}
