.modal-fullscreen .modal-content {
  background: transparent !important;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.modal-backdrop.fade.show  {
  background: rgba(0, 0, 0, 0.6);
}

.modal-backdrop.show  {
  background: rgba(0, 0, 0, 0.6);
}

.modal {
  background: rgba(0, 0, 0, 0.5);

  .modal-content {
    border-radius: 8px;
    height: 100%;
    overflow: hidden;
  }

  .modal-dialog-centered {
    align-items: center;
    // align-items: unset !important;

    .modal-content {
      // margin-top: 261px;
    }
  }
}

