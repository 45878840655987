// line height는 각각, 나머지는 공통으로
//
@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);

%fontLandTitle {
  font-family: 'NanumSquare', san-serif;
  font-size: 46px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: -1px;
  text-align: left;
}

%fontLandMsg {
  font-family: 'NanumSquare', san-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.5px;
  text-align: left;
}

%fontLandSubTitle {
  font-family: Noto Sans KR;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.5px;
  text-align: right;
}

%fontPopTitle {
  font-family: Noto Sans KR;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.5px;
  text-align: left;
}

%fontPopMsg {
  font-family: Noto Sans KR;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.5px;
  text-align: left;
}

%fontXXlarge {
  font-family: Apple SD Gothic Neo, Noto Sans KR;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.5px;
  text-align: left;
}

%fontXLarge {
  font-family: Apple SD Gothic Neo, Noto Sans KR;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.3px;
  text-align: left;
}

%fontLarge {
  font-family: Apple SD Gothic Neo, Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.3px;
  text-align: left;
}

%fontNomal {
  font-family: Apple SD Gothic Neo, Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.3px;
  text-align: left;
}

%fontSmall {
  font-family: Apple SD Gothic Neo, Noto Sans KR;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  letter-spacing: -0.30000001192092896px;
}

%fontXsmall {
  font-family: Apple SD Gothic Neo, Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;

  letter-spacing: -0.3px;
  text-align: left;
}

// Input Label /////////////////////////////////
%inputLabel {
  font-family: Apple SD Gothic Neo, Noto Sans KR;
  font-size: 0.875em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.063em;
  letter-spacing: -0.3px;
  text-align: left;
}

// Button  ///////////////////////////////
@mixin btn($height, $radius, $style) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: $height;
  border-radius: $radius;

  font-family: Apple SD Gothic Neo, Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.3px;
  text-align: left;
  line-height: 18px;

  // &.#{$style} {
  @if ($style == 'line') {
    background-color: transparent;
    color: #131415;
    border: 1px solid #e7e8e9;

    &:hover {
      background-color: #fff;
      color: #131415;
    }

    &:focus {
      border-radius: 8px;
      outline: none;
      box-shadow: none;

      background-color: transparent;
      color: #131415;
      border: 2px solid #0085ff;
    }
    &:disabled {
      background-color: transparent;
      color: #9ca0a3;
    }
  }

  @if ($style == 'primary') {
    background-color: #007eff;
    color: #fff;

    &:hover {
      background-color: #007eff;
      color: #fff;
    }

    &:focus {
      border-radius: 8px;
      outline: none;
      box-shadow: none;

      background-color: #007eff;
      color: #fff;
      border: 2px solid #007eff;
    }
    &:disabled {
      background-color: #e7e8e9;
      color: #9ca0a3;
    }
  }
  // }
}

/// flex ////////////////////////////////////
@mixin flex($direcrion, $align, $justice) {
  display: flex;
  flex-direction: $direcrion;
  align-items: $align;
  justify-content: $justice;
}

/// form input ////////////////////////////////////
%input {
  padding: 8px 12px;
  @extend %fontNomal;
  border-radius: 8px;
  border: 1px solid #c9ccce;
  color: #4f5356;

  &:focus {
    border: 2px solid #9ca0a3;
    color: #131415;
  }

  &:disabled {
    border: 1px solid #f5f5f5;
    color: #9ca0a3;
  }

  &.critical {
    border: 1px solid #c93131;
    background-color: #ffebeb;
    color: #131415;
  }
}
