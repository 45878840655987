.landing-wrap {
  @include flex(column, flex-start, flex-start);
  margin-bottom: 400px;
  width: 100%;

  .section {
    width: 100%;
  }

  .msg-wrap {
    .main-title {
      @extend %fontLandTitle;
    }

    .main-msg {
      @extend %fontLandMsg;
    }
  }

  .sub-title {
    @extend %fontLandSubTitle;
  }
}

@media screen and (min-width: 768px) {
  .landing-wrap {
    margin-bottom: 170px;
    
    .section {
      width: calc(100% - 15px);
      padding-left: 30px;
      padding-right: 30px;
      margin: 0 auto;
    }
  }
}

/////////////////////////// section 1
.section1 {
  background-image: linear-gradient(#f8f8ff, #f0f0ff);

  .header {
    @include flex(row, center, space-between);
    padding: 0 24px;
    height: 51px;

    .logo-wrap {
      @include flex(row, center, flex-start);

      .symbol {
        width: auto;
        height: 35px;
      }
    }

    .menu-wrap {
      display: none;

      .text-menu {
        @extend %fontXLarge;
        line-height: 18px;
        color: #212126 !important;
      }

      .btn-menu {
        display: inline-block;
        min-width: 76px;
        height: 48px;
        border-radius: 24px;
        padding: 15.5px 16.5px;
        line-height: 18px;
        text-align: center;
        cursor: pointer;
        @extend %fontXLarge;
        color: #fff !important;

        &.black {
          background-color: #383d46;
        }

        &.primary {
          background-color: #007EFF;
        }
      }
    }

    .mobile {
      position: absolute;
      top: 52px;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      position: absolute;

      background: rgb(100, 86, 255);
      transition: all 0.5s ease 0s;
      z-index: 1;
      padding: 26px;

      .text-menu,
      .btn-menu {
        color: #fff !important;
        padding: 18px;
        height: auto;
        text-align: center;
        margin: 0 0 15px 0;
        border-radius: 10px;
        background-color: rgb(138, 136, 255) !important;
        @include flex(row, center, flex-start);
        gap: 16px;
      }
    }

    .icon-menu {
      display: inline-block;
      width: 32px;
      height: 32px;

      &:before {
        content: '';
      }
    }
  }

  .inner {
    @include flex(column, center, center);
    padding-top: 50px;

    .msg-wrap {
      @include flex(column, center, center);
      padding: 0 20px;

      .main-title {
        font-size: 26px;
        color: #000;
        line-height: 38px;
      }

      .main-msg {
        padding-top: 16px;
        font-size: 14px;
        line-height: 19px;
        color: #4e4f52; 
        font-weight: 600;
        text-align: center;
      }
    }

    .usage-wrap {
      &.pc {
        display: none;
      }

      &.mobile {
        display: flex;
        flex-direction: column;
      }
    }

    .mobile-usage { 
      padding-top: 61px;

      .title {
        display: none;
      }

      .type {
        @include flex(row, flex-start, center);
        
        a {
          padding: 0 8px;
        }

        img {
          width: 155px;
          height: auto;
        }
      }
    }

    .visual-wrap {
      padding: 30px 22vw 0 22vw;
      @include flex(null, center, center);
      width: 100%;

      img {
        object-fit: contain;
        // filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 15px 12px);
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .section1 {
    @include flex(column, center, flex-start);
    // height: 747px;
    overflow: hidden;

    .header {
      width: 100%;
      max-width: 1135px;
      height: 99px;
      padding: 0;

      .menu-wrap {
        display: block;

        .text-menu {
          margin-right: 28px;
        }

        .btn-menu {
          margin-right: 10px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .icon-menu {
        display: none;
      }
    }

    .inner {
      // @include flex(column, flex-start, flex-start);
      // position: relative;
      // width: calc(100% - 15px);
      // max-width: 1135px;
      // height: 648px;
      // padding-top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 0;
      

      .msg-wrap {
        align-items: center;
        
        .main-title {
          font-size: 46px;
          line-height: 66px;
        }

        .main-msg {
          font-size: 24px;
          line-height: 32px;
          color: #000;
          font-weight: 500;
          text-align: center;
        }
      }

      .video-wrap {
        @include flex(column, center,  flex-start);
        width: 100%;
        padding: 100px 0 0 0;

        .visual-wrap {
          padding: 0;
        }

        .video-section {
          padding-top: 50px;
          width: 584px;

          video {
            width: 584px;
            max-width: 100%;
            height: auto;
          }
        
          .btn-wrap {
            .chip {
              width: 100px;
              padding: 16px 20px 14px;
              border-radius: 54px;
              line-height: 22px;
              font-size: 22px;
              font-weight: 700;
            }
          }
        }
      }

      .usage-wrap {
        &.pc {
          display: none;
        }
  
        &.mobile {
          display: flex;
          flex-direction: row;
        }
      }
  
      .mobile-usage { 
        padding-top: 61px;
  
        .title {
          display: block;
          font-size: 16px;
          color: #9CA0A3;
          font-weight: 500;
          padding-bottom: 24px;
        }

        .type {
          display: flex;
          margin-bottom: 24px;
          flex-direction: column;

          a {
            padding-bottom: 24px;
          }

          img {
            width: 100%;
            height: auto;
          }
          
        }

      }
  
      .visual-wrap {
        padding: 50px 0 0 30px;
        @include flex(null, flex-start, center);
        width: 320px;
        height: 400px;
        overflow: hidden;
  
        img {
          object-fit: contain;
        }
      }
    } 
  }
} 

@media screen and (min-width: 940px) {
  .section1 {
    
    .inner {
      @include flex(column, flex-start, flex-start);
      position: relative;
      width: calc(100% - 15px);
      max-width: 1135px;
      height: 648px;
      padding-top: 0;
      

      .msg-wrap {
        align-items: flex-start;
        padding: 0;
        width: 50%;
        // width: calc(50% - 20px);
        // padding-right: 20px;
        
        .main-title {
          font-size: 46px;
          line-height: 66px;
        }

        .main-msg {
          font-size: 24px;
          line-height: 32px;
          text-align: left;
          color: #000;
          font-weight: 500;
        }
      }

      .video-wrap {
        @include flex(row, flex-start, space-between);
        width: 100%;
        padding: 130px 0 0 0;
        

        .visual-wrap {
          padding: 0;
        }

        .video-section {
          width: 50%;
          padding-top: 0;
          padding-left: 35px;
          // width: calc(50% - 20px);

          video {
            width: 100%;
            max-width: 100%;
            height: auto;
          }
        
          .btn-wrap {
            .chip {
              width: 100px;
              padding: 16px 20px 14px;
              border-radius: 54px;
              line-height: 22px;
              font-size: 22px;
              font-weight: 700;
            }
          }
        }
      }

      .usage-wrap {
        @include flex(row, flex-start, space-between);
        width: calc(100% - 25px);
        padding-top: 47px;

        &.mobile {
          display: none;
        }

        &.pc {
          display: flex;
        }

        .visual-wrap  {
          width: 261px;
          max-width: 261px;
          height: auto;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .section1 {
    
    .inner {
      .video-wrap {
        .video-section {
          width: 584px;
          padding-left: 0;

          video {
            width: 584px;
          }
        }
      }

      .usage-wrap {
        width: 470px;
      
        .visual-wrap  {
          width: 261px;
          max-width: 261px;
        }
      }
    }
  }
}

////////// section2, 3 공통 ///////////////////////////////
.section2,
.section3,
.section4,
.section5 {
  padding: 48px 24px;
  background-color: #fcfbff;

  .msg-wrap {
    .main-title {
      color: #222224;
      font-size: 24px;
      line-height: 35px;
      padding-bottom: 30px;
      text-align: center;

      span {
        display: block;
      }
    }

    .main-msg {
      color: #7b7b7d;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
    }
  }
}

@media screen and (min-width: 768px) {
  .section2,
  .section3,
  .section4,
  .section5 {
    @include flex(column, center, center);
    padding: 120px 0;

    .msg-wrap {
      width: 100%;
      max-width: 1135px;

      .main-title {
        font-size: 43px;
        line-height: 62px;
        padding-bottom: 24px;
        text-align: left;

        span {
          display: inline;
        }
      }

      .main-msg {
        font-size: 24px;
        line-height: 32px;
        text-align: left;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .section2,
  .section3,
  .section4,
  .section5 {
    @include flex(column, center, center);
    padding: 120px 0;

    .msg-wrap {
      width: 100%;
      max-width: 1135px;

      .main-title {
        font-size: 43px;
        line-height: 62px;
        padding-bottom: 24px;
        text-align: left;

        span {
          display: inline;
        }
      }

      .main-msg {
        font-size: 24px;
        line-height: 32px;
        text-align: left;
      }
    }
  }
}

/////////////////////////// section 2 ///////////////////////////
.section2 {
  background-color: #fff;
  .inner {
    padding-top: 45px;
    display: grid;
    gap: 45px;
    grid-auto-flow: row;
    // grid-template-rows: 1fr 1fr;

    .inner-img-wrap {
      @include flex(null, center, center);
      position: relative;
      padding: 0 13.5px;

      img {
        object-fit: contain;
      }
    }

    .inner-msg-wrap {
      .sub-title {
        font-size: 22px;
        line-height: 30px;
        padding-bottom: 30px;
        text-align: center;

        &.last {
          padding-top: 40px;
        }
      }

      .btn-wrap {
        @include flex(column, center, center);
        gap: 24px;

        .btn {
          width: 152px !important;
          font-weight: 500 !important;

          &.primary {
            @include btn(42px, 4px, primary);
          }

          &.line {
            @include btn(42px, 4px, line);
          }
        }

        a {
          text-decoration: none !important;
          
          .btn {
            text-decoration: none !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .section2 {
    .inner {
      width: calc(100% - 15px);
      max-width: 1135px;
      @include flex(row, center, space-between);
      padding-top: 96px;
      gap: 56px;

      .inner-img-wrap {
        padding: 0;

        img {
          object-fit: contain;
        }
      }

      .inner-msg-wrap {
        .sub-title {
          font-size: 26px;
          line-height: 34px;
          text-align: right;

          &.last {
            padding-top: 91px;
          }
        }

        .btn-wrap {
          flex-direction: row;
          justify-content: flex-end;
        }
      }
    }
  }
}

/////////////////////////// section 3 ///////////////////////////
.section3 {
  padding: 48px 0 30px 0;
  background-color: #f9f9f9;

  .msg-wrap {
    padding: 0 46px;
  }

  .artwork-wrap {
    @include flex(column, center, center);
    padding: 24px 4px;
    gap: 12px;
    text-decoration: none !important;

    span {
      color: #1b1e25;
      @extend %fontNomal;
      line-height: 18px;
      text-decoration: none !important;
    }
  }

  .hospital-wrap {

    > span {
      display: block;
      height: 280px;
      // background: url('../../assets/landing/sec3/sec3.png') left top / auto 150px repeat-x;
    }
  }
}

@media screen and (min-width: 768px) {
  .section3 {
    padding: 120px 0 60px 0;
    background-color: #fff;

    .msg-wrap {
      padding: 0;
    }

    .artwork-wrap {
      max-width: 1135px;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      padding: 24px 0 76px;
      gap: 11px;
    }

    .hospital-wrap {
      width: 100%;
      // height: 280px;

      > span {
        width: 100%;
        height:280px;
        background: url('../../assets/landing/sec3/sec3.png') left top / auto 280px repeat-x;
      }
    }
  }
}

.swiper-wrap {
  width: 100%;
  min-width: 320px;
  height: 100%;
}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100%;
  height: 280px;
}

.banner-wrap {
  @include flex(column, center, center);
  height: 280px;

  .banner-top {
    width: 100%;
    @include flex(column, flex-start, center);
  }

  .banner-bottom {
    width: 100%;
    @include flex(column, flex-end, center);
  }

  .banner {
    @include flex(column, flex-start, flex-start);
    min-width: 195px;
    height: 108px;
    border-radius: 16px;
    box-shadow: 0 4px 16px 4px rgba(0, 0, 0, 0.04);
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.04);

    .logo-bg {
      @include flex(column, center, center);
      width: 40px;
      height: 40px;
      margin: 4px;
      border-radius: 16px;
      background-color: #f6f7f8;
    }

    .h-name {
      @extend %fontLarge;
      line-height: 18px;
      color: #1B1E25;
    }

    &.mgt24 { margin-top: 24px; }
  }

  
}


/////////////////////////// section 4 ///////////////////////////
.section4 {
  padding: 48px 0 0 0;
  background-color: #fcfbff;
  @include flex(column, center, center);

  .msg-wrap {
    padding: 0 0 32px 0;
    
  }

  .chip-wrap {
    display: none;
    overflow-x: hidden;
    padding: 32px 0 32px 28px;

    .chip {
      // float: left;
      padding: 10px 14px;
      // margin-right: 8px;
      border-radius: 20px;
      @extend %fontLarge;
      line-height: 18px;
      border: 1px solid #e6e8ed;
      background-color: #fff;
      white-space: nowrap;
      cursor: pointer;
      

      &:hover, &:active, &.selected {
        color: #fff;
        background-color: #383d46;
        border-color: #383d46;
      }
    }
  }

  // slide
  .carousel {
    .slider-wrapper  {
      display: inline-block;
      max-width: 300px;
      height: 390px;
      border-radius: 24px;
      box-shadow: 0 5px 8px 0 rgba(0,0,0,.1);
      margin: 0 10px 10px 10px;
    }

    .carousel-status {
      display: none;
    }

    .control-dots {
      bottom: 20px;
      max-width: 300px;
    }
  }

  .slide-wrap {

    .slide-inner {
      border-radius: 24px;
      overflow: hidden;
      box-shadow: 0 5px 3px 0 rgba(0, 0, 0, 0.05);
    }

    .overlay {
      padding: 24px 24px 37px 24px;
      background-color: #fff;
      height: 150px;
      // border-radius: 24px 24px 0 0;
      
      .slide-title {
        @include flex(column, flex-start, flex-start);
        font-family: Noto Sans KR;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: -0.5px;
        text-align: left;
        color: #222224;
        padding-bottom: 6px;
      }

      .slide-message {
        @extend %fontLarge;
        font-weight: 400;
        color: #4F5356;
        line-height: 20px;
      }
    }

    .img {
      border-radius: 0 0 24px 24px;
      width: 100%;
      height: 240px;

      &.img1 {
        background: url('../../assets/landing/sec4-img6.png') center center / cover no-repeat;
      }

      &.img2 {
        background: url('../../assets/landing/sec4-img1.jpeg') center center / cover no-repeat;
      }

      &.img3 {
        background: url('../../assets/landing/sec4-img2.png') center center / cover no-repeat;
      }

      &.img4 {
        background: url('../../assets/landing/sec4-img5.jpeg') center center / cover no-repeat;
      }

      &.img5 {
        background: url('../../assets/landing/sec4-img4.jpeg') center center / cover no-repeat;
      }
      
      &.img6 {
        background: url('../../assets/landing/sec4-img3.jpeg') center center / cover no-repeat;
      }

    }

  }
}

@media screen and (min-width: 768px) {
  .section4 {
    padding: 65px 0;

    .inner {
      max-width: 1135px;
      width: 100%;
    }

    .msg-wrap {
      // padding: 0 46px 0 0;

      .main-title,
      .main-msg {
        text-align: center;
      }

      .main-title {
        span:last-child {
          display: block;
        }
      }

      .main-msg {
        padding-bottom: 30px;
      }
    }

    .carousel .slider-wrapper  {
      max-width: 478px;
      height: 564px;
    } 

    .carousel {
      display: flex;
      justify-content: center;
  
      .control-dots {
        max-width: 100%;
      }
    }
  

    .slide-wrap {
     
      .slide-inner {
        border-radius: 24px;
        overflow: hidden;
        box-shadow: 0 5px 8px 0 rgba(0,0,0,.1);
        
      }
  
      .overlay {
        padding: 40px 40px 61px 40px;
        background-color: #fff;
        height: 252px;
        
        .slide-title {
          font-size: 32px;
          line-height: 46px;
          padding-bottom: 12px;
        }
  
        .slide-message {
          font-size: 24px;
          line-height: 32px;
        }
      }
  
      .img {
        border-radius: 0 0 24px 24px;
        vertical-align: top;
        height: 312px;
      }
     
    }
  }
}

@media screen and (min-width: 1075px) {
  .section4 {
    padding: 65px 0;

    .inner {
      max-width: 1135px;
      width: 100%;
      @include flex(row-reverse, center, center);
      gap: 84.5px;

      .msg-wrapper {
        @include flex(column, flex-end, center);
        gap: 64px;
        width: 561px;
      }
    }

    .msg-wrap {
      padding: 0 46px 0 0;

      .main-title,
      .main-msg {
        text-align: right;
      }

      .main-title {
        span {
          display: block;
        }
      }
    }

    .chip-wrap {
      display: block;
      width: 100%;
      padding: 0;
      text-align: right;

      .chip {
        float: none;
        display: inline-block;
        padding: 16px 20px;
        margin-right: 12px;
        margin-bottom: 12px;
        border-radius: 80px;
        font-weight: 700;
        font-size: 22px;
        line-height: 22px;
      }
    }

    .carousel .slider-wrapper  {
      max-width: 478px;
      height: 564px;
    } 

    .carousel {
      display: flex;
      justify-content: center;
  
      .control-dots {
        max-width: 100%;
      }
    }
  

    .slide-wrap {
     
      .slide-inner {
        border-radius: 24px;
        overflow: hidden;
        box-shadow: 0 5px 8px 0 rgba(0,0,0,.1);
        
      }
  
      .overlay {
        padding: 40px 40px 61px 40px;
        background-color: #fff;
        height: 252px;
        
        .slide-title {
          font-size: 32px;
          line-height: 46px;
          padding-bottom: 12px;
        }
  
        .slide-message {
          font-size: 24px;
          line-height: 32px;
        }
      }
  
      .img {
        border-radius: 0 0 24px 24px;
        vertical-align: top;
        height: 312px;
      }
     
    }
  }
}


// @media screen and (min-width: 1075px) {
//   .section4 {
//     .msg-wrap {
//       .main-title {
//         span {
//           display: block;
//         }
//       }
//     }
//   }
// }

/////////////////////////// section 5 ///////////////////////////
.section5 {
  padding-top: 65px;
  background-color: #fff;

  .letter-wrap {
    border-radius: 24px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.04);
    padding: 32px 16px;
    margin-top: 40px;
    border: 1px solid #E0E1E3;

    .letter-title {
      @extend %fontNomal;
      color: #222224;
      line-height: 18px;
      padding-bottom: 32px;
      text-align: center;
      font-weight: 600;
    }

    .letter-inner {
      .input-wrap {
        padding-bottom: 24px;

        .label {
          @extend %fontNomal;
          line-height: 18px;
          padding-bottom: 5px;
        }

        .input-block {
          //   height: 36px;
          width: calc(100% - 2px);

          &.input-style {
            @extend %input;
          }

          > textarea {
            border: 0;
            outline: none;
            width: 100%;
            height: 150px;

            &::placeholder {
              color: #4f5356;
            }
          }

          .count {
            @extend %fontNomal;
            line-height: 18px;
            padding: 3px 9px;
            color: #4f5356;
            text-align: right;
          }
        }

        .form-control {
          @extend %input;
          
          &:hover,
          &:active,
          &:focus {
            border-color: #6456ff !important;
            box-shadow: 0px 0 4px 0 #6456ff !important;
          }

          &::placeholder {
            color: #4f5356;
          }
        }
      }

      .box {
        padding: 8px 12px;
        border-radius: 8px;
        background-color: #e6e8ed;
        @extend %fontNomal;
        line-height: 18px;
        color: #4f5356;

        ul {
          margin-bottom: 0;
        }
      }

      // checkbox
      .checkbox-wrap {
        position: relative;
        margin-bottom: 8px;

        input[type='checkbox'] {
          display: none;

          + label {
            cursor: pointer;
            padding-left: 32px;
            margin-bottom: 0;
            color: #1b1e25;
            @extend %fontNomal;
            line-height: 18px;

            &:before {
              content: '';
              background: url('../../assets/landing/unchecked.png') left top / 24px 24px no-repeat;
              width: 24px;
              height: 24px;
              margin-right: 10px;
              position: absolute;
              top: 50%;
              margin-top: -12px;
              left: -2px;
            }
          }

          &:checked {
            + label {
              &:before {
                background: url('../../assets/landing/checked.png') left top / 24px 24px no-repeat;
              }
            }
          }
        }
      }

      


      .form-group {
        padding-bottom: 24px;

        .col-md-3 {
          display: block;
          color: #4f5356;
          line-height: 18px;
          @extend %fontNomal;
          padding-bottom: 3px;

          label {
            padding: 0;
            margin: 0;
          }
        }

        .form-control {
          @extend %input;
          border-color: #6456ff;
          box-shadow: 0px 0 4px 0 #6456ff;

          &:hover,
          &:active,
          &:focus {
            border-color: #6456ff;
            box-shadow: 0px 0 4px 0 #6456ff;
          }

          &::placeholder {
            color: #4f5356;
          }
        }
      }

      .sc-fzoXzr {
        padding-top: 28px;
        border-top: 1px solid #e4e5e7;
        justify-content: center;
        margin: 32px -16px 0;
      }

      .sc-AxhUy {
        @include btn(42px, 4px, 'primary');
      }

      .css-yk16xz-control {
        border-radius: 8px;
      }

      .css-1wa3eu0-placeholder {
        color: #4f5356;
      }

      .css-1pahdxg-control {
        border-color: #6456ff;
        box-shadow: 0px 0 4px 0 #6456ff;

        &:hover,
        &:focus {
          border-color: #6456ff;
          box-shadow: 0px 0 4px 0 #6456ff;
        }
      }
    }

    .btn-wrap {
      display: flex;
      padding-top: 28px;
      border-top: 1px solid #e4e5e7;
      justify-content: center;
      margin: 32px -16px 0;

      .btn {
        width: 117px !important;
        @include btn(42px, 4px, 'primary');
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .section5 {
    padding-top: 40px;

    .msg-wrap {
      .main-title,
      .main-msg {
        text-align: center;
      }
    }
    .letter-wrap {
      max-width: 554px;
      margin-top: 40px;
      padding-left: 32px;
      padding-right: 32px;

      .letter-title {
        font-family: Noto Sans KR;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: -0.5px;
        text-align: left;
      }

      .letter-inner {
        .checkbox-wrap {
          margin-bottom: 10px;
          margin-top: 10px;
        }

        .box {
          margin-bottom: 32px;
        }

        .card {
          .card-body {
            .sc-fzoXzr {
              padding-right: 32px;
              margin: 32px -32px 0;
              justify-content: flex-end;
            }
          }
        }
      }

      .btn-wrap {
        justify-content: flex-end;
        margin: 32px -32px 0;
        padding-right: 32px;

      }
    }
  }
}

.signup-wrap {
  @include flex(row, center, center);
  gap: 10px;
  margin-top: 40px;

  @media screen and (min-width: 768px) {
    gap: 20px;
  }

  .btn-menu {
    text-decoration: none;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 48px;
    border-radius: 24px;
    text-align: center;
    cursor: pointer;
    
    background-color: #007EFF;

    > span {
      line-height: 18px;
      @extend %fontXLarge;
      color: #fff !important;
      font-weight: 700 !important;
    }

    .icon-arrow {
      width: 5px;
      height: 9px;
      margin-left: 6px;
      margin-top: -2px;
    }
  }
}


/////////////////////////// 서비스 문의 (기존) ///////////////////////////
.email-btn-wrap {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  -webkit-box-pack: center;
  justify-content: center;
  

  @media screen and (min-width: 571px) {
    padding-top: 50px;
  }
}



.send-email {
  margin: 0;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid rgb(28, 1, 192);
  border-radius: 8px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background-color: rgb(240, 235, 255);
  
  
  @media only screen and (min-width: 571px) {
    margin: 16px 48px;
  }
}



.copyright {
  @extend %fontPopMsg;
  font-size: 14px;
  line-height: 18px;
  color: #552BFE;
  padding: 6px 104px 10px 16px;
  
  a {
    color: rgb(100, 86, 255) !important;
    &:hover {text-decoration: none;}
  }

  @media only screen and (min-width: 571px) {
    font-size: 18px;
  }
}

.email-btn {
  background: linear-gradient(#492BFE, #3413FF);
  border-radius: 0 4px 4px 0;
  padding: 10px 16px;

}


// video
.video-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  .video {
    width: 100%;
    height: auto;
    margin: 0 auto;
    // border-radius: 10px;
    // border: 1px solid #ccc;

    &.hide { display: none; }

  }


  .btn-wrap {
    @include flex(row, center, center);
    gap: 12px;
    padding-top: 24px;

    .chip {
      width: 67px;
      padding: 10px 14px;
      border-radius: 38px;
      @extend %fontLarge;
      line-height: 18px;
      border: 1px solid #e6e8ed;
      background-color: #fff;
      white-space: nowrap;
      cursor: pointer;
      

      &:hover, &:active, &.selected {
        color: #fff;
        background-color: #383d46;
        border-color: #383d46;
      }
    }

    a {
      text-decoration: none !important;
      
      .btn {
        text-decoration: none !important;
      }
    }
  }
}

// banner
.blog-banner-wrap {
  width: 100%;
  height: 150px;
  background-color: #f3f3f3;
  
  .blog-banner {
    width: auto;
    height: 100%; 
    background: url('../images/blog-banner-mo.png') center no-repeat;
    background-size: contain;
  }

  @media screen and (min-width: 1135px ) {
    height: 200px;

    .blog-banner {
      width: auto;
      height: 100%;
      background: url('../images/blog-banner.png') center no-repeat;
      background-size: contain;
    }
  }
}