// Add own custom styles.
:root {
  --success: #4dbd74;
  --danger: #f86c6b;
  --warning: #ffc107;
  --primary: #29b6ca;
  --second: #20a8d8;

  //Primary
  // --mpPrimary500: #5F4ADC;
  // --mpPrimary400: #817EFF;
  // --mpPrimary300: #8a88ff;
  // --mpPrimary200: #B8B7FF;
  // --mpPrimary100: #D4D3FF;

  //Primary
  --mpPrimary500: #2f7dff;
  --mpPrimary400: #3096f5;
  --mpPrimary300: #8a88ff;
  --mpPrimary200: #B8B7FF;
  --mpPrimary100: #d8f1ff;

  //Secondary
  --mpSecondary500: #2B5EFE;
  --mpSecondary400: #1a7fe4;
  --mpSecondary300: #96AFFF;
  --mpSecondary200: #BCCCFF;
  --mpSecondary100: #D5DFFF;

  //Gradient
  --mpGradientForButton: linear-gradient(0deg, rgba(100,86,255,1) 0%, rgba(129,126,255,1) 100%);
  --mpGradientForSymbol: linear-gradient(0deg, rgba(138,136,255,1) 0%, rgba(184,183,255,1) 100%);
  --mpGradientForBackground: #linear-gradient(0deg, rgba(214,225,255,1) 0%, rgba(122,100,255,1) 100%);
  
  //Neutrial
  --gray100: #f0f3f5;
  --gray200: #e4e7ea;
  --gray300: #c8ced3;
  --gray400: #acb4bc;
  --gray500: #8f9ba6;
  --gray600: #73818f;
  --gray800: #2f353a;
  --gray900: #23282c;
  --gray700: #5c6873;
  --black: #000000;
  --white: #ffffff;
  --white: #F9FAFB;  // NOTE: override 
  --primary10: #e9f7f9;
  --primary30: #bee9ef;
  --red10: #fee9e9;
  --red30: #fcd2d2;
  --redActive: #c24141;

  //Interactive
  --interactive-default: #0085FF;
}

.od-input-wrapper {
  border: 1px solid #dfe0e2;

  &.disabled {
    border: 1px solid #f6f7f7;
    

    .od-input {
      color: #ababab !important;
      background-color: #f6f7f7 !important;
    }
  }
}

input:-moz-placeholder, 
input::-moz-placeholder,
input:-ms-input-placeholder,
input::-webkit-input-placeholder
input::placeholder {
  color: #ababab;
  font-size: 13px;
}


a.grayed_link {
  display: block;
  color: #7F8386 !important;
  font-weight: 500;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    display: inline-block;
    margin-bottom: 0;
  }
}

.footer_line {
  display: none;

  @media (min-width: 768px) {
    display: inline-block;
    margin-top: 5px;
  }
}

.enum_tag_pending {
  width: 64px;
  height: 22px;
  left: calc(50% - 64px/2 + 60px);
  top: calc(50% - 20px/2);
  background: #FBD38D;
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 13px;
  line-height: 17px;
  color: #131415;
  text-align: center;
}

.enum_tag_proxy {
  width: 42px;
  height: 22px;
  left: calc(50% - 64px/2 + 60px);
  top: calc(50% - 20px/2);
  background: #BCE7FF;
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 13px;
  line-height: 17px;
  color: #131415;
  text-align: center;
  display: inline-block;
  margin: 0 3px;

  ~ span {
    display: inline-block;
  }
}
.enum_tag_proxy_empty {
  width: 42px;
  height: 22px;
  left: calc(50% - 64px/2 + 60px);
  top: calc(50% - 20px/2);
  background: #BCE7FF;
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 13px;
  line-height: 17px;
  color: #131415;
  text-align: center;
  display: none;
  margin: 0 3px;

  ~ span {
    display: inline-block;
  }
}

.enum_tag_patient_no_show,
.enum_tag_doctor_no_show,
.enum_tag_patient_cancel,
.enum_tag_doctor_cancel,
.enum_tag_canceled,
.enum_tag_refused,
.enum_tag_not_paied {
  width: 64px;
  height: 22px;
  left: calc(50% - 64px/2 + 60px);
  top: calc(50% - 20px/2);
  background: #FFB8B8;
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 13px;
  line-height: 17px;
  color: #131415;
  text-align: center;
}

.enum_tag_receipted,
.enum_tag_ready,
.enum_tag_waiting,
.enum_tag_requested {
  width: 64px;
  height: 22px;
  left: calc(50% - 64px/2 + 60px);
  top: calc(50% - 20px/2);
  background: #9DECF9;
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 13px;
  line-height: 17px;
  color: #131415;
  text-align: center;
}

.enum_tag_prescription,
.enum_tag_in_progress,
.enum_tag_accepted {
  width: 64px;
  height: 22px;
  left: calc(50% - 64px/2 + 60px);
  top: calc(50% - 20px/2);
  background: #BAEAD3;
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 13px;
  line-height: 17px;
  color: #131415;
  text-align: center;
}

.enum_tag_finished,
.enum_tag_paied {
  width: 64px;
  height: 22px;
  left: calc(50% - 64px/2 + 60px);
  top: calc(50% - 20px/2);
  background: #E6E8ED;
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 13px;
  line-height: 17px;
  color: #202223;
  text-align: center;
}

.form-control {
  border-radius: 8px !important;

  &:focus {
    border: 1px solid var(--gray400) !important;
    outline: 0 !important;
    box-shadow:  0 0 0 0.2rem rgba(125, 125, 128, 0.25) !important;
  }
}
.form-group {
  border-radius: 8px;
  border: 1px solid #C9CCCE;
  margin: 0;
  background-color: #fff;
  
  &:hover {
    border: 1px solid #9CA0A3;
  }
  .input-group {
    border: 0;
    .form-control {
      border: 0 !important;
      box-shadow: none !important;
    }
    .input-group-prepend {
      :hover {
        border: 0;
      }
    }
    .input-group-text {
      border-radius: 8px 0 0 8px;
      border: 0;
      i {
        font-weight: 600;
      }
      :hover {
        border: 0;
      }
    }
  }
}

//DatePicker
.DateRangePicker {
  border: 1px solid #C9CCCE;
  border-radius: 8px !important;
}
.DateRangePickerInput {
  //border-radius: 10px !important;
  background-color: transparent !important;
  display: flex !important;
  flex-direction: row;
  align-items: center;

}
.DateRangePickerInput_arrow.DateRangePickerInput_arrow_1 svg {
  width: 12px;
  height: 16px;
  font-size: 16px;
  font-weight: 600;
  color: var(--gray600);
  margin-left: 8px;
  margin-right: 8px;
}
.DateRangePickerInput__withBorder {
  border: 0 !important;
}
.DateInput {
  background-color: transparent !important;
  border-radius: 8px !important;

  :hover {
    border: 1px solid #9CA0A3;
  }
}
.DateInput_input {
  width: 130px;
  height: 36px;
  border-radius: 8px !important;
  font-size: 15px !important;
  line-height: 1.43;
  color: var(--gray800);
  font-weight: 400 !important;
  background-color: transparent !important;

}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">"
}

.medi-popup-device {
  position: absolute;
  z-index: 2000;
  top: -43px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.card {
  margin-bottom: 0 !important;
}

.row.form-group{
  border: 0 !important;
  width: 100%;
  max-width: 100%;
  .col-md-3 {
    // display: none;

    label {
      display: none;
    }
  }
}

.col-12.col-md-9 {
  padding: 0;
  width: 100%;
  max-width: 100% !important;
  flex: 1 !important;

}

@media (min-width: 768px) {
  .col-12.col-md-9 {
    flex: 100% !important;
    max-width: 100% !important;
    padding: 0;
  }
}

.label-table-list {
  font-weight: 500;
  font-size: 14px;
}
.col-md-3 {
  padding: 0 !important;
}


//테이블 관련
.listTitle {
  color: var(--gray800) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.table {
  margin-bottom: 0 !important;
}
.table-responsive {
  border-radius: 8px !important;
}
.table-hover tbody tr:hover {
  background: #f4f5f8;
}
.table thead {
  background: #F6F8FA;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #c8ced3;
  border-top: 0;
  text-align: center;
}

.table td {
    border: 0;
    border-bottom: 1px solid #c8ced3;
}

table {
  border: 0 !important;
}

.doctor_name {
  white-space: nowrap;
  > span {
    white-space: nowrap;
  }
}


.profile-uploader {
  div {
    border-radius: 100px;
    :hover {
      background: #f4f5f8;
    }
    input {
      display: contents;
    }
    i {
      margin-bottom: 6px !important;
    }
    div {
      &:hover {
        visibility: visible !important;
      }
      input {
        display: contents;
      }
    }
  }

  div, img {
    border-radius: 100px !important;
  }
}

html:not([dir="rtl"]) .sidebar {
  margin-left: -240px;
}

@media only screen and (max-width: 570px) {
  main.main {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  html:not([dir="rtl"]) .sidebar {
    margin-left: 0;
  }
}




.app-header {
  &.navbar {
    .navbar-toggler {
      min-width: 25px !important;
      margin-right: 6px;

      ~ div {
        a {
          line-height: 1;
    
          img {
            display: inline-block;
            width: 100px;
            height: auto;
            max-width: none;
          }
        }
      }
    }

    .dropdown-toggle {
      position: relative;
      width: 100px;
      font-size: 11px;
      padding: 5px 15px 5px 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &:after {
        position: absolute;
        top: 50%;
        right: 5px;
        z-index: 1;
      }
    }
  }
}

@media only screen and (min-width: 380px) {
  .app-header {
    &.navbar {
      .navi-toggle {
        ~ div {
          a {
            img {
              width: 130px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 441px) {
  .app-header {
    &.navbar {
      .navbar-toggler {
        margin-right: 8px;

        ~ div {
          a {
            img {
              width: 156px;
              height: 27px;
    
              &.logo-doctor, &.logo-manager {
                width: 146px;
              }
            }
          }
        }
      }
  
      .dropdown-toggle {
        width: auto;
        font-size: 13px;
        padding: 0.25rem 15px 0.25rem 0.5rem;
      }
    }
  }
}


.sidebar-minimizer {
  display: none;
}

//버튼
.single-icon-btn {
  background-size: 24px !important;
  background-position: center !important;
  width: 34px !important;
  height: 34px !important;

  &:hover {
    background-color: var(--gray200) !important;
  }
}


//버튼
.single-icon-btn-new {
  background-size: 24px !important;
  background-position: center !important;

  &:hover {
    background-color: var(--gray200) !important;
  }
}


@media (min-width: 992px) {
  .app-body {
    main {
      margin-left: 240px !important;
    }
  }
}

input[type="radio"]:checked {

}
input[type="radio"] {
  width: 18px;
  height: 18px;
}



.toggleButton {
  display: inline-flex;
}


.pagination {
  .page-item {
    margin-right: 8px;

    button {
      border-color: transparent !important;
      background-color: transparent !important;
      color: #878a8f !important;
      box-shadow: none;
      outline: none;
      border-radius: 0 !important;

      &:focus {
        box-shadow: none !important;
        outline: none !important;
      }
    }

    &.od-pagination-item {
      

      button {
        color: #ababab !important;
        border-color: #e0e1e4 !important;
        background-color: #fff !important;
      }
  
      &.active {
        button {
          color: #424242 !important;
          border-color: #878a8f !important;
          background-color: #fff !important;
        }
      }
    }
  }
}