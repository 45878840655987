.root {
  width: 100%;
}

.tab-wrap {
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
}

// 기본 화이트컬러 탭
.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #e6e8ed;
  border-radius: 20px;
  padding: 10px 14px;
  height: 38px;
  margin-right: 8px;

  > span {
    @extend %fontLarge;

    line-height: 1;
    color: #7f8694;
  }

  // 선택된 탭
  &:hover {
    background-color: #fff;
    box-shadow: inset 0 0 2px 0 #666;
    cursor: pointer;

    > span {
      color: #1b1e25;
    }
  }

  &.selected,
  &:active {
    cursor: pointer;
    background-color: #f5f5f5;
    box-shadow: inset 0 0 2px 0 #666;
    // border: 2px solid #afb5c2;

    > span {
      color: #1b1e25;
    }
  }


  // 검정색 탭이 선택되었을 경우.
  &.black {
    

    &:hover {
      background-color: #f5f5f5;
      box-shadow: inset 0 0 2px 0 #666;
      border: 1px solid #666;
    

      > span {
        color: #1b1e25;
      }
    }

    &.selected,
    &:active {
      background-color: #383d46;
      border: 1px solid #383d46;

      > span {
        color: #ffffff;
      }
    }
  }
}
