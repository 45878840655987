.popup-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //   background-color: #2d2e2e;
  background-color: rgba($color: #000000, $alpha: 0.8);
  z-index: 2000;
  

  &.popup-white {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.popup-header {
  display: flex;
  flex-direction: row;
  padding: 32px 45px 0 30px;
  justify-content: space-between;

  .logo-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    > label {
      @extend %fontNomal;
      line-height: 18px;
      font-weight: 500;
      color: #fff;
      margin-bottom: 0;
      padding-left: 16px;
      color: #fff;
    }

    > img {
      width: 30px;
      height: 30px;
    }
  }

  .btn-close {
    padding: 11.5px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.popup-container {
  height: calc(100% - 75px);
  display: flex;
  align-items: center;
  justify-content: center;

  .popup-inner {
    width: 560px;

    .title {
      padding-bottom: 38px;
      @extend %fontPopTitle;
      line-height: 36px;
      color: #fff;
    }

    .message {
      @extend %fontPopMsg;
      line-height: 24px;
      padding-bottom: 38px;
      color: #fff;
    }

    .input-wrap {
      &.margin {
        padding-bottom: 57px;
      }

      .label {
        @extend %fontNomal;
        margin-bottom: 0;
        line-height: 20px;
        letter-spacing: 0px;
        color: #fff;
        padding-bottom: 3px;
      }

      .input {
        width: 100%;
        height: 36px;
        padding: 6px 12px 7px;
        border-radius: 8px;
        border: 1px solid #c9ccce;
        background-color: #fff;

        input {
          width: 100%;
          height: 100%;
          border: 0;
          outline: none;
          @extend %fontNomal;
          line-height: 18px;
          background-color: transparent;

          &::placeholder {
            color: #4f5356;
          }
        }

        &.alert {
          border-color: #c93131;
          background-color: #ffebeb;
          margin-bottom: 0;
        }
      }
    }

    .valification-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-top: 3px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }

      span {
        @extend %fontNomal;
        line-height: 20px;
        color: #d72c0d;
      }
    }
  }

  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    padding-top: 48px;

    .btn {
      padding: 12px 24px;
      
      &.primary {
        @include btn(42px, 4px, primary);
      }
    }
  }
}

.hide {
  display: none;
}

/// white popup
.pop-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: auto;
  width: 612px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 1px 8px 8px 0 rgba($color: #000000, $alpha: 0.15);

  .popup-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    justify-content: space-between;
    border-bottom: 1px solid #E1E3E5;
    width: 100%;
    height: 66px;

    > label {
      @extend %fontPopMsg;
      line-height: 24px;
      font-weight: 500;
      color: #202223;
      margin: 0;
      padding-left: 16px;
    }
  }

  .popup-container {
    padding: 20px;
    @extend %fontXLarge;
    color: #202223;
    //
    line-height: 20px;

    p {
      font-weight: 400;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .popup-bottom {
    width: 100%;
    padding: 16px;
    border-top: 1px solid #E4E5E7;
    display: flex;
    justify-content: flex-end;s
  
    .btn {
      padding: 12px 24px;
      
      &.primary {
        @include btn(42px, 4px, primary);
      }
    }
  }
}
