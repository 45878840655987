.card-wrap {
  padding: 0 16px;
  // height: calc(100vh - 430px);
  height: calc(100% - 259px);
  overflow-y: auto;
}

// card
.epi-card {
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid #e7e8e9;
  border-radius: 8px;
  cursor: pointer;

  &.selected,
  &:hover,
  &:active {
    border-color: #7f8386;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
  }
}
.status-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10.5px;

  .date {
    flex: 1;
    text-align: right;
    color: #1b1e25;
    @extend %fontNomal;
    line-height: 18px;
  }
}

.column {
}

.hospital {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 10.5px;

  img {
    width: 18px;
    height: 18px;
    margin: 3.5px 6px;
  }

  span {
    flex: 1;
    text-align: left;
    @extend %fontXXlarge;
    line-height: 25px;
  }
}

.diagnosis {
  padding-bottom: 8px;
  @extend %fontLarge;
  line-height: 18px;
  font-weight: 500;
}

.procedure {
  padding: 8px 12px;
  background-color: #f0f2f4;
  border-radius: 12px;
  margin-bottom: 8px;
  @extend %fontLarge;
  line-height: 18px;

  &.white {
    background-color: #FFFFFF;
  }
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
  }

  span {
    padding-left: 4px;
    @extend %fontXsmall;
    line-height: 16px;
    color: #7f8694;
  }
}

// badge
.status-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 8px;
  // width: 40px;
  height: 23px;
  border-radius: 23px;
  @extend %fontNomal;
  line-height: 17px;
  white-space: nowrap;

  &.purple {
    background-color: #e9e5ff;
  }
  &.yellow {
    background-color: #fee9c1;
  }
  &.green {
    background-color: #bbf7d9;
  }
  &.blue {
    background-color: #c2e2ff;
  }
}
